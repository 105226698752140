/* Overwrite bootstrap
----------------------------------*/
.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    background-color: #0b97c4 !important;
    border-color: #0b97c4 !important;
}
.table tbody tr.error td {
    background-color: #f2dede;
}
.overridemarginbottom {
    margin-bottom: 2px !important;
}
blockquote {
    border-right: 5px solid #eeeeee;
}
.table tbody tr.warn td {
    background-color: #fcf8e3;
}
.fg-cyan {
    color: #1ba1e2 !important;
}
.fg-zonecolor {
    color: #0b97c4 !important;
}
.panel-body p img {
    max-width: 95%;
}
.panel-body img {
    max-width: 95%;
}
.borderspace {
    margin-bottom: 3px;
}
.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

input[readonly] {
    background-color: white !important;
    cursor: text !important;
}

img.typeicon {
    width: 16px;
    height: 16px;
}

.fg-red {
    color: #AA0000;
}
.fg-green {
    color: #00A000;
}
.bg-partner {
    color: white !important;
    font-weight: bold;
    background: #0b97c4 !important;
}
.bg-red {
    background: #eea29a !important;
}
.bg-blue {
    background: #92a8d1 !important;
}
.bg-addon {
    background: #ffeead !important;
}

.dataTables_filter {
    float: right !important;
}

.smallist {
    padding: 2px 5px 2px 5px !important;
}

.print-black {
    padding: 2px;
    background-color: black;
    color: white;
}

.print-cyan {
    padding: 2px;
    background-color: cyan;
    color: black;
}

.print-yellow {
    padding: 2px;
    background-color: yellow;
    color: black;
}

.print-magenta {
    padding: 2px;
    background-color: magenta;
    color: white;
}

.marginbottom-small {
    margin-bottom: 2px !important;
}

.margintop-med {
    margin-top: 10px !important;
}

.slidermanager {
    position: relative;
    float: left;
    width: 200px;
    margin: 10px;
}
.slidermanager .deletebutton {
    float: right;
}
.slidermanager img {
    width: 100%;
}

.carousel {
    margin-bottom: 15px;
}

.buttonsmallborder {
    padding: 2px 5px 2px 5px !important;
}

