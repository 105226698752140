.topbackground {
    background-color: #0b97c4;
}
.ribbonbar {
    display: block;
    background: #F1F1F1;
    height: 85px;
    width: 100%;
    margin-bottom: 10px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.navtitlebar {
    display: block;
    color: #FFF;
    vertical-align: middle;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 100%;
}
.navgroup {
    display: inline-block;
    float: left;
    height: 77px;
    min-width: 55px;
    background: #F1F1F1;
    border-right: 1px solid #D2D2D2;
    border-left: 1px solid #FFF;
    padding: 0 4px 0px 4px;
    margin: 4px 0;
    position: relative;
    vertical-align: top;
    font-size: 12px;
}
.navgroup .header {
    position: absolute;
    left: 0;
    bottom: -3px;
    height: 16px;
    display: block;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    color: rgba(0, 0, 0, 0.8);
    background-color: rgba(0, 0, 0, 0.05);
}
.navgroup .navbutton {
    position: relative;
    display: inline-block;
    padding: 6px 6px 6px 6px;
    height: 60px;
    min-width: 60px;
    text-align: center;
    vertical-align: top;
    border-radius: 3px;
    text-decoration: none !important;
    color: inherit;
}

.navgroup .navbutton img {
    width: 32px;
    height: 32px;
}
.navgroup .navbutton-small {
    position: relative;
    display: inline-block;
    padding: 1px 3px 1px 3px;
    min-width: 110px;
    border-radius: 1px;
    text-decoration: none !important;
    color: inherit;
}

.navgroup .navbutton-small img {
    width: 16px;
    height: 16px;
    margin-top: -2px;
}

.navgroup a:hover {
    background-color: #B8B8B8;
}
a:hover.nav-link {
    color: rgba(0, 0, 0, 0.8) !important;
}
.nav-ribbontab {
    font-size: 12px;
}
.nav-ribbontab .active {
    background-color: rgba(0, 0, 0, 0.05) !important;
    border-bottom: 0px !important;
}
.nav-ribbontab a {
    text-decoration: none !important;
    color: white;
}
.nav-ribbontab .active a {
    text-decoration: none !important;
    color: rgba(0, 0, 0, 0.8);
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    background-color: #F1F1F1 !important;
}
.helperbar {
    float: right;
}
.helperbar img {
    margin-right: 5px;
}